import React , {useEffect, useState, useContext, SetStateAction}from "react";
import styles from './prompt_command_options.module.css'
import AgentsCommand from "./agents_command";
import ToolsCommand from "./tools_command";
import PipeCommand from "./pipe_command";
import { EmbeddedMenu } from "../../menus/embedded";
import { TerminalContext, TextArea } from "../terminal";
import { BsReverseBackspaceReverse } from "react-icons/bs";
import useAxiosAuthClient from "src/network/interceptors";
import { PromptFileUploadList } from "../../displays/uploaded_files";
import { LineStyle } from "@mui/icons-material";
import BaseButton from "../../buttons/buttons";
import { NewsAgentCommand } from "./agents/news_agent.module";
import { FundamentalsAgentCommand } from "./agents/fundamentals_agent";
import { ChartAgentCommand } from "./agents/chart_agent";
import { error } from "console";
import ReportAgentCommand from "./agents/report_command";

interface CommandOptionsI{
    lineIndex : number,
    containerIndex : number,
}

export function ListCommands({ lineIndex, containerIndex }: CommandOptionsI)
{
    const [options, setOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const terminalContext = useContext(TerminalContext);
    const AxiosAuthClient = useAxiosAuthClient();


    useEffect(() => {
        const fetchOptions = async () => {
            try {
                setLoading(true);
                const response = await AxiosAuthClient.get(`/commands/options`);
                setOptions(response.data);
            } catch (err) {
                setError('Failed to fetch command options');
            } finally {
                setLoading(false);
            }
        };
        fetchOptions();
    }, []);

    const updateCommand = (option : string)=>{
        terminalContext?.updateQuery(lineIndex, containerIndex, option);
        
    }

    return (
        <div className={styles.command_list}>
            {options.map((option)=>[
                <BaseButton children={null} label={option} key={option} onClick={()=>{updateCommand(option)}} className={styles.command_list_option}></BaseButton>
            ])}
        </div>
    )

}

interface CommandOptionsI {
    lineIndex: number;
    containerIndex: number;
}

export function ScheduleCommand({lineIndex, containerIndex }: CommandOptionsI)
{
    return(
        <form className={styles.agent_command}>
            <div className={styles.agent_prompt} style={{ display: "flex", flexDirection: "column", alignItems: "center", boxSizing: "border-box" }}>
                <textarea
                    name="fundamentalsAgentQuery"
                    placeholder={"{Company Name} {Financial Statement}"}
                />
            </div>
        </form>
    )
}


export function CommandError({ lineIndex, containerIndex }: { lineIndex: number, containerIndex: number })
{
    const [errorString, setErrorString] = useState<string | null>(null);
    const context = useContext(TerminalContext);

    useEffect(() => {
        const newStatus = context?.lines[lineIndex]?.input?.containers[containerIndex]?.status;
        if (newStatus) setErrorString(newStatus);
    }, [context?.lines[lineIndex]?.input?.containers[containerIndex]?.status]);

    return (
        <div className={styles.commandError}>
            {errorString}
        </div>
    );
}

export default function CommandOptions({ lineIndex, containerIndex }: CommandOptionsI) {
    const context = useContext(TerminalContext);
    const [commandComponent, setCommandComponent] = useState<React.ReactNode | null>(null);
    const [command, setCommand] = useState(false);

    useEffect(() => {

        const query = context?.lines[lineIndex].input.containers[containerIndex].query;
        const new_query = query?.slice(0, query.length - 2);
        

        switch (query) {
            case  query?.slice(-2, query.length) === "||" && query.length > 2 ? query : undefined:
                if (query === undefined) return;
                context?.setLines((prev) => {
                    const updatedLines = [...prev];
                    updatedLines[lineIndex].input.containers[containerIndex].query = query?.slice(0, -2);
                    return updatedLines;
                });
                context?.appendInput();
                break;
                
            case  query?.slice(-2, query.length) === "\\\\" && query.length > 2 ? query : undefined:
                if (query === undefined) return;
                context?.setLines((prev) => {
                    const updatedLines = [...prev];
                    updatedLines[lineIndex].input.containers[containerIndex].query = query?.slice(0, -2);
                    return updatedLines;
                });
                context?.appendInput();
                break;

            case "@reset":
                context?.clearInputContainers(lineIndex)
                break;

            default:
                context?.updateContainerType(lineIndex, containerIndex, "text")
                setCommandComponent(null);
        }

        
    }, [context?.lines[lineIndex].input.containers[containerIndex].query]);
    

    return (
        commandComponent ? (
            <div className={styles.command_options}>
                {commandComponent}
            </div>
        ) : null
    );
}