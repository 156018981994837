import { AppContext } from '../../pages/chat/chat_app'
import styles from './chat_navbar.module.css'
import React, { useState, useContext, createContext, useEffect } from 'react'
import { AccountButton } from '../buttons/buttons'
import { Home } from '@mui/icons-material'
import { ControlPanelContext } from '../control_panel/control_panel'
import { useIndexedDB } from 'src/client_side_storage/indexed_db'
import { useAuth0 } from "@auth0/auth0-react";
import logo from '../../static/images/metaloss_Logo_final_white.png'
import logo_text from '../../static/images/metaloss_text_white.png'
import { LuPanelLeft } from "react-icons/lu";
import { motion } from "framer-motion";

import { PlayIcon, FileIcon, EyeOpenIcon, ChatBubbleIcon, ViewVerticalIcon } from '@radix-ui/react-icons'

const NavBarContext = createContext({});


export function NavbarButtons({children}:{children:React.ReactNode})
{ 
    return (
        <div className={styles.navbar_buttons}>
            {children}
        </div>
    )           
}

export default function ChatNavbar()
{
    const [showSettings, setShowSettings] = useState(false);
    const appContext = useContext(AppContext);
    const [expanded, setExpanded] = useState(false);
    const {user} = useAuth0();

    const togglePromptSearch = ()=>{
        const promptList = document.getElementById("promptList");
        if (promptList)
        {
            if (promptList.style.display === "none")
            {
                promptList.style.display = "block";
            }
            else
            {
                promptList.style.display = "none";
            }
        }
    }

    return (
        <NavBarContext.Provider value={{showSettings, setShowSettings}}>
            <nav
            id="navbar" className={`h-[100%] flex flex-col justify-between bg-neutral-800 border-r border-r-neutral-600 ${expanded ? 'w-[12%]' : 'w-fit gap-2'}`}>
                {/* navbar header */}
                <div className={`flex flex-row justify-between items-center w-full border-b-neutral-700 border-b ${expanded ? 'p-4' : 'p-2'}`}>
                    <div className='flex flex-row items-center'>
                        <img src={logo} alt="" className={`flex ${expanded ? "h-5" : 'h-4'}`}/>
                        {expanded ? <img src={logo_text} className='h-4'/> : undefined}
                    </div>
                    <button onClick={()=>{setExpanded(!expanded)}} className="flex items-center self-center p-1 gap-1 rounded">
                        <LuPanelLeft className={`text-white hover:bg-transparent ${expanded ? 'size-5' : 'size-4'}`}/>
                    </button>
                </div>
                <div className={`flex flex-col ${expanded ? 'gap-0' : 'gap-2'}`}>
                    <button className={`flex items-center self-center ${expanded ? "gap-2 w-full p-4" : 'w-fit rounded p-2'} ${appContext?.activeTab === 0 ? 'bg-neutral-800': ''}`} onClick={() => {appContext?.setActiveTab(0); togglePromptSearch() }}>
                        <ChatBubbleIcon className={`text-white ${expanded ? 'size-4' : 'size-5'}`}/>
                        {expanded ? <div className="text-xs font-bold text-white">Chats</div> : undefined}
                    </button>                    
                    <button className={`flex items-center self-center p-2 ${expanded ? "gap-2 w-full p-4" : 'w-fit rounded p-2'} ${appContext?.activeTab === 1 ? 'bg-neutral-800': ''}`} onClick={() => {appContext?.setActiveTab(1)}}>
                        <FileIcon className={`text-white ${expanded ? 'size-4' : 'size-5'}`}/>
                        {expanded ? <div className="text-xs font-bold text-white">Documents</div> : undefined}
                    </button>
                    <button className={`flex items-center self-center p-2 ${expanded ? " gap-2 w-full p-4" : 'w-fit rounded p-2'} ${appContext?.activeTab === 2 ? 'bg-neutral-800': ''}`} onClick={() => {appContext?.setActiveTab(2)}}>
                        <EyeOpenIcon className={`text-white ${expanded ? 'size-4' : 'size-5'}`}/>
                        {expanded ? <div className="text-xs font-bold text-white">Graph</div> : undefined}
                    </button>
                </div>

                <NavbarButtons>
                    {/* <ToggleLightModeButton onClick={() => appContext?.setTheme(appContext?.theme === 'light' ? 'dark' : 'light')}></ToggleLightModeButton> */}
                    <button className={`flex items-center self-center ${expanded ? "gap-2 w-full p-4" : 'w-fit rounded hover:bg-transparent p-2'}`}>
                        <AccountButton onClick={()=>{appContext?.setShowMenu(appContext.showMenu === true ? false : true)}}></AccountButton>
                        {expanded ? <div className="text-xs text-white">{user?.email}</div> : undefined}
                    </button>
                </NavbarButtons>
            </nav>
        </NavBarContext.Provider>
    )
}