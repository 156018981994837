import React, {useState} from "react";
import SignInComponent from "src/components/auth/sign_in";
import SignUpComponent from "@/src/components/auth/sign_up";
import AuthForm from "src/components/auth/AuthForm";
import styles from "./authentication_page.module.css"
import BaseButton from "src/components/buttons/buttons";
import { Auth0Login } from "src/components/auth/auth0Auth";
import envVariables from "src/env";

import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export default function AuthPage() {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        loginWithRedirect()
    }, [loginWithRedirect]);

    return null;
}