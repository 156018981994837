import React, {act, createContext, ReactNode, useContext, useRef, useState} from "react";
import { AppContext } from "src/pages/chat/chat_app";
import styles from './control_panel.module.css'
import buttonStyles from '../buttons/buttons.module.css'


import BaseButton from "../buttons/buttons";

import GraphView from "./graph_explorer/graph";
import { DocumentPanel } from "./documents/document_panel";
import Prompts from "./prompts/prompts";

export const ControlPanelContext = createContext({
    activeTab:0,
    setActiveTab:(tab:number)=>{}
});

export const useControlPanelContext = ()=>useContext(ControlPanelContext);

interface tabProps{
    isActive: boolean;
    onClick: ()=>void;
    tabName: string;
    children : React.ReactNode;
}

export function ControlTabContent({children, tabName}:{children : React.ReactNode, tabName:string})
{
    return (
        <div className="h-[100%] w-[100%] bg-neutral-950">
            {children}
        </div>
    )
}

export function ControlTab({ isActive, onClick, tabName, children} : tabProps)
{
    return (
        <div>
            <BaseButton label={null} onClick={onClick} className={isActive ? styles.active_tab_button : ""}>
                {children}
            </BaseButton>
        </div>

    )
}

export default function ControlPanel()
{
    const appContext = useContext(AppContext);
    const [activeTab, setActiveTab] = useState(0);
    const tabNames = ["Agents", "Database", "Tasks", "Tools"];

    const showActiveTabContent = ()=>{
        switch (appContext?.activeTab)
        {
            case 0:
                return (
                    <ControlTabContent tabName={tabNames[activeTab]}>
                        <Prompts/>
                    </ControlTabContent>
                )
            case 1:
                return (
                    <ControlTabContent tabName={tabNames[activeTab]}>
                        <DocumentPanel/>
                    </ControlTabContent>
                )
            case 2:
                return (
                    <ControlTabContent tabName={tabNames[activeTab]}>
                        <GraphView></GraphView>
                    </ControlTabContent>
                )
            default:
                return (
                    <ControlTabContent tabName={tabNames[activeTab]}>
                        <Prompts/>
                    </ControlTabContent>
                )
        }
    }

    return (
        <ControlPanelContext.Provider value={{ activeTab, setActiveTab }}>
            <div className="flex-1 flex-col h-[100%] items-center box-border">
                {showActiveTabContent()}
            </div>
        </ControlPanelContext.Provider>
    )
}