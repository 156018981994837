const envVariables = {
    backendUrl: process.env.REACT_APP_BACKEND_URL || "none",
    hostname : process.env.REACT_APP_HOST_NAME || "none",
    auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID || "none",
    auth0Secret: process.env.REACT_APP_AUTH0_SECRET || "none",
    auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || "none",
    auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE || "none",
    mixpanelKey: process.env.REACT_APP_MIXPANEL_TOKEN || "none"
};
  
export default envVariables;