import React, {forwardRef, useContext, useEffect} from "react";
import { TerminalContext } from "../terminal/terminal";
import { TerminalLineI } from "../terminal/terminal";
import useAxiosAuthClient from "src/network/interceptors";
import axios from "axios";

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  lineIndex: number;
  containerIndex: number;
}

const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
    (props, ref) => {
        const { lineIndex, containerIndex, ...rest } = props
        const terminalContext = useContext(TerminalContext);
        const AxiosAuthClient = useAxiosAuthClient();

        useEffect(() => {
          const uploadToken = new AbortController();
          terminalContext?.setCancelToken(uploadToken);
        }, []);

        const addFilesToContainer = (fileNames: string[]) => {
          terminalContext?.setLines((prevLines: TerminalLineI[]) => {
            if (lineIndex >= prevLines.length || containerIndex >= prevLines[lineIndex].input.containers.length) {
              console.error('Invalid lineIndex or containerIndex');
              return prevLines;
            }
            const lineToUpdate = { ...prevLines[lineIndex] };
            const containerToUpdate = { ...lineToUpdate.input.containers[containerIndex] };
            const updatedContainer = {
              ...containerToUpdate,
              files: [...containerToUpdate.files, ...fileNames],
            };
            const updatedLine = {
              ...lineToUpdate,
              input: {
                ...lineToUpdate.input,
                containers: [
                  ...lineToUpdate.input.containers.slice(0, containerIndex),
                  updatedContainer,
                  ...lineToUpdate.input.containers.slice(containerIndex + 1),
                ],
              },
            };
            return [
              ...prevLines.slice(0, lineIndex),
              updatedLine,
              ...prevLines.slice(lineIndex + 1),
            ];
          });
        };
        

        const handleFileUpload = async (files: FileList | null) => {
          if (!files) return;
          const uniqueFiles = Array.from(files).map((file, index) => {
            const uniqueId = `${Date.now()}${index}`;
            const uniqueFileName = `${file.name.trim()}`;
            return new File([file], uniqueFileName, { type: file.type });
          });
          const formData = new FormData();
          uniqueFiles.forEach((file) => {
            formData.append('files', file);
          });
          formData.append('step', containerIndex.toString());
          
          try {
            const uploadToken = new AbortController();
            terminalContext?.setCancelToken(uploadToken);
            terminalContext?.setUploadComplete("loading");
            terminalContext?.updateContainerStatus(lineIndex, containerIndex, "uploading")
            const response = await AxiosAuthClient.post(
              '/uploads/uploadFiles',
              formData,
              {signal: terminalContext?.cancelToken?.signal}
            );
            
            if (response.status === 200) {
              const uploads  = response.data.filenames;
              terminalContext?.updateContainerStatus(lineIndex, containerIndex, "success")
              terminalContext?.setUploadComplete("success");
            } else {
              console.error('Error uploading files');
              terminalContext?.updateContainerStatus(lineIndex, containerIndex, "error")
              terminalContext?.setUploadComplete("error");
            }
          } catch (error) {
            if (axios.isCancel(error)) {
              
              terminalContext?.setUploadComplete("canceled");
            } else {
              console.error('Error:', error);
              terminalContext?.setUploadComplete("error");
            }
          }
      };
          

      return (
          <input
              ref={ref}
              type="file"
              id="fileInput"
              accept=".pdf,.xlsx,.xls,.doc,.docx,.pptx,.txt,.csv,.png,.jpg,.jpeg"
              style={{ display: 'none' }}
              multiple={true}
              onChange={(e) => {
                if (e.target.files) {
                  const filenames: string[] = Array.from(e.target.files).map((file) => file.name);
                  addFilesToContainer(filenames);
                  handleFileUpload(e.target.files);
                }
              }}
          />
      );
    }
);

export default FileInput;