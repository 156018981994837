import React, { createRef, forwardRef, useContext } from "react";
import { Children, useEffect, useImperativeHandle, useState, useRef, createContext } from 'react';
import { PromptFileUploadList } from "../displays/uploaded_files";
import { UploadButton } from "../buttons/buttons";
import CommandOptions from "./commands/prompt_command_options.module";
import useChat from "./chat";

import MarkdownRenderer from "../markdown";

import styles from './terminal.module.css';
import '../../styles/global.css';

import FileInput from "../forms/file_input";
import BeatLoader from "../spinners/beatloader";
import { CircleSpinner } from "react-spinners-kit";
import { AppContext } from "src/pages/chat/chat_app";
import { useToast } from "../notification/toast";
import { CopyIcon, UploadIcon } from "@radix-ui/react-icons";
import BarLoader from "../spinners/loaders";
import { useAuth0 } from "@auth0/auth0-react";

interface TerminalContextI {
	appendInput : ()=>void, 
	createDefaultInput : ()=>React.ReactNode,
	appendContainer : (type: string, children: React.ReactNode)=>void,
	resetCommand : boolean,
	setResetCommand : (resetCommand : boolean)=>void,
	addNewLine : ()=>void,
	clearInputContainers: (lineIndex: number)=>void,
	addNewContainer : (line: TerminalLineI, type: string, children: React.ReactNode)=>void,
	sendingQuery : boolean,
	setSendingQuery : (sendingQuery : boolean)=>void,
	updateQuery : (lineIndex: number, containerIndex: number, value: string) =>void,
	resetContainer: (lineIndex: number, containerIndex: number) =>void
	updateOutputContainer : (lineIndex: number,containerIndex: number,newChild: React.ReactNode)=>void,
	updateInputContainer : (lineIndex: number,containerIndex: number,newChild: React.ReactNode)=>void,
	updateContainerStatus : (lineIndex: number, containerIndex: number, newStatus: string) =>void,
	updateContainerCommand : (lineIndex: number, containerIndex: number, command: Record<string,any>) =>void,
	updateContainerType : (lineIndex: number, containerIndex: number, newType: string) => void,
	lines : TerminalLineI[],
	defaultLine : TerminalLineI,
	defaultInputContainer: InputContainerI,
	setLines :React.Dispatch<React.SetStateAction<TerminalLineI[]>>;
	showCommandOptions : string,
	setShowCommandOptions : (showCommandOptions : string) => void,
	uploadFiles : FileList | null;
	setUploadFiles: (files : FileList | null)=>void;
	uploadComplete : string;
	setUploadComplete: (uploadComplete:string)=>void;
	fileInputRefs: React.MutableRefObject<(HTMLInputElement | null)[][]>;
	cancelToken : AbortController | null,
	setCancelToken : (cancelToken : AbortController | null)=>void,
	isDone : boolean,
	setIsDone : (isDone : boolean)=>void
}

export const TerminalContext = createContext<TerminalContextI | undefined>(undefined)

interface TerminalLineProps {
	length: number,
	children: React.ReactNode
}

interface TextAreaProps {
	autoFocus: boolean | undefined;
	onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> | undefined;
	onInput : ((e: React.ChangeEvent<HTMLTextAreaElement>)=>void) | null;
	className: string | undefined;
	readOnly?: boolean;
	value : string;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
	({autoFocus, onKeyDown, onInput, className, readOnly }, ref) => {
		const context = useContext(TerminalContext);
		const textAreaRef = useRef<HTMLTextAreaElement>(null);

		function autoResizeTextarea(textarea: HTMLTextAreaElement | null) {
			if (textarea) {
				textarea.style.height = 'auto';
				textarea.style.height = textarea.scrollHeight + 'px';
			}
		}

		const resizeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
			autoResizeTextarea(event.currentTarget);
		};

		const handleOnInput = (event : React.ChangeEvent<HTMLTextAreaElement>)=>{
			if (onInput) onInput(event)
			resizeHandler(event);
		}

		return (
			<textarea
				className={className}
				ref={ref}
				rows={1}
				onInput={handleOnInput}
				onKeyDown={onKeyDown}
				readOnly={readOnly}
			/>

		);
	}
);

export function TerminalLine({children, length} : TerminalLineProps)
{
	return (
		<div className={styles.terminal_line}>
			{children}
		</div>
	);
}

interface TerminalInputContainerI {
	lineIndex : number,
	containerIndex : number,
	ref: React.RefObject<HTMLDivElement> | null;
	children: React.ReactNode;
}

export const TerminalInputContainer = React.forwardRef<HTMLDivElement, TerminalInputContainerI>(
    ({ lineIndex, containerIndex, children }, ref) => {
        const terminalContext = useContext(TerminalContext);
		const nlines = terminalContext?.lines?.length ? terminalContext.lines.length - 1 : 0;
		const containersLen = terminalContext?.lines?.[nlines]?.input?.containers?.length ? terminalContext.lines[nlines].input.containers.length - 1 : 0;
		let isCurrent = false;
		if (lineIndex === nlines && containerIndex === containersLen)
			isCurrent = true

        return (
			<div ref={ref} className="flex flex-col bg-neutral-700 resize-none outline-none text-contrast-50 w-[100%] h-fit ">
				{children}
			</div>
        );
    }
);

interface TerminalOutputContainerI {
	lineIndex : number,
	containerIndex : number,
	ref: React.RefObject<HTMLDivElement> | null;
	children: React.ReactNode;
}

export const TerminalOutputContainer = React.forwardRef<HTMLDivElement, TerminalOutputContainerI>(
    ({ lineIndex, containerIndex, children }, ref) => {
        const terminalContext = useContext(TerminalContext);

		return (
			<div className="relative">
				<div ref={ref} className="flex flex-col bg-neutral-800 resize-none outline-none text-contrast-50 w-[100%] h-fit">
					{children}
				</div>
			</div>
        );
    }
);


export interface TerminalLineI {
	chat_id?: string;
	input: {
		containers: {
			ref: React.RefObject<HTMLDivElement>;
			type : string;
			query : string;
			command : Record<string, string | number | boolean | File>,
			status : string | null;
			files: string[]
		}[];
	};
	output:{
		containers: {
			ref: React.RefObject<HTMLDivElement>;
			type : string,
			child: React.ReactNode | null;
			rawText?: string;
		}[];
	} 
}

export interface InputContainerI {
    ref: React.RefObject<HTMLDivElement>;
	type : string;
	query : string;
	command : Record<string, string | number | boolean | File>,
	status : string | null;
	files: string[]
}

export default function Terminal()
{
	const appcontext = useContext(AppContext);
	const terminalRef = useRef<HTMLDivElement|null>(null);
	const {showToast} = useToast();
	const fileInputRefs = useRef<(HTMLInputElement | null)[][]>([]);

	
	const [resetCommand, setResetCommand] = useState<boolean>(false);
	const [cancelToken, setCancelToken] = useState<AbortController | null>(null);
	const [uploadFiles, setUploadFiles] = useState<FileList | null>(null);
	const [uploadComplete, setUploadComplete] = useState<string>("");
	const [sendingQuery, setSendingQuery] = useState<boolean>(false);
	const [isDone, setIsDone] = useState<boolean>(false);
	const [showCommandOptions, setShowCommandOptions] = useState<string>("");
	const conversation = useChat();

	const defaultInputContainer : InputContainerI = {
		ref: createRef<HTMLDivElement>(),
		query : "",
		type : "text",
		command:{},
		status : null,
		files: []
	}
	
	const defaultLine = {
		input: {
			containers: [
				defaultInputContainer
			]
		},
		output: {
			containers: [
				{ref:React.createRef<HTMLDivElement>(), type:"text", child:null}
			]
		}
	}

	const [lines, setLines] = useState<TerminalLineI[]>(
		[defaultLine],
	);

	useEffect(() => {
		if (conversation && conversation.length > 0) {
		  const updatedLines = conversation.map((convItem) => {
			const inputContainers = convItem.input.containers.map((inputItem) => ({
				ref: React.createRef<HTMLDivElement>(),
				type: inputItem.type || "text",
				query: inputItem.query,
				command: inputItem.command || {},
				status: inputItem.status || null,
				files: inputItem.files || [],
			}));
			const outputContainers = convItem.output.containers.map((outputItem) => ({
				ref: React.createRef<HTMLDivElement>(),
				type: outputItem.type,
				child: (
					<MarkdownRenderer markdown={outputItem.rawText ? outputItem.rawText : ""}></MarkdownRenderer>
				),
				rawText: outputItem.rawText,
			}));
	  
			return {
			  input: {
				containers: inputContainers,
			  },
			  output: {
				containers: outputContainers,
			  },
			};
		  });

		  const lines : TerminalLineI[] = [...updatedLines, defaultLine];
		  setLines(lines);
		  
		}
	}, [conversation]);
	
	const addNewLine = () => {	
		setLines((prevLines) => [
			...prevLines,
			defaultLine,
		]);
	};

	const resetContainer = (lineIndex: number, containerIndex: number) => {
		setLines((prevLines) => {
			const updatedLines = [...prevLines];
			const lineToUpdate = updatedLines[lineIndex];
			if (lineToUpdate && lineToUpdate.input.containers[containerIndex]) {
				const containerToReset = lineToUpdate.input.containers[containerIndex];
				containerToReset.ref = createRef<HTMLDivElement>();
				containerToReset.type = "text";
				containerToReset.query = "";
				containerToReset.command = {};
				containerToReset.status = null;
				containerToReset.files = [];
			}
			return updatedLines;
		});
	};

	const clearInputContainers = (lineIndex: number) => {
		setLines((prevLines) => {
			const updatedLines = [...prevLines];
			const lineToUpdate = updatedLines[lineIndex];
			if (lineToUpdate) {
				lineToUpdate.input.containers = [defaultInputContainer];
			}
			return updatedLines;
		});
	};
	
	const appendContainer = (type: string, children: React.ReactNode, query: string = "") => {
		const newRef = React.createRef<HTMLDivElement>();
		const newContainer = { ref: newRef, query, type: type, childRef: createRef<HTMLTextAreaElement>(), child: children, command: {}, status : null, files: [] };
		
		setLines((prevLines) => {
			const lastLineIndex = prevLines.length - 1;
			if (lastLineIndex < 0) return prevLines;
			
			const lastLine = { ...prevLines[lastLineIndex] };
			const updatedContainers = [
				...lastLine.input.containers,
				newContainer
			];
			
			const updatedLine = {
				...lastLine,
				input: {
					...lastLine.input,
					containers: updatedContainers,
				},
			};

			return [
				...prevLines.slice(0, lastLineIndex),
				updatedLine,
				...prevLines.slice(lastLineIndex + 1),
			];
		});
	};
	
	const createDefaultInput = ()=>{
		return (
			<TextArea autoFocus={true} onKeyDown={undefined} onInput={null} value="" className="text-sm text-contrast-50 bg-neutral-700 w-full resize-none p-2 outline-none"/>
		)
	}

	const appendInput = ()=>{
		appendContainer("text", createDefaultInput())
	}

	const addNewContainer = (line: TerminalLineI, type: string, children: React.ReactNode) => {
		const newRef = React.createRef<HTMLDivElement>();
		const newContainer = { 
			ref: newRef,
			query : "",
			type: type, 
			childRef: createRef<HTMLTextAreaElement>(),
			child: children,
			command:{},
			status: null, 
			files:[]
		};


		setLines((prevLines) => {
			const lineIndex = prevLines.findIndex(l => l === line);
			if (lineIndex === -1) return prevLines;

			const updatedContainers = [
				...prevLines[lineIndex].input.containers,
				newContainer
			];

			const updatedLine = {
				...prevLines[lineIndex],
				input: {
					...prevLines[lineIndex].input,
					containers: updatedContainers,
				},
			};

			return [
				...prevLines.slice(0, lineIndex),
				updatedLine,
				...prevLines.slice(lineIndex + 1),
			];
		});
	};

	const updateContainerStatus = (lineIndex: number, containerIndex: number, newStatus: string) => {
		setLines((prevLines) => {
			const updatedLines = [...prevLines];
			if (updatedLines[lineIndex] && updatedLines[lineIndex].input.containers[containerIndex]) {
				const updatedLine = { ...updatedLines[lineIndex] };
				const updatedContainer = { ...updatedLine.input.containers[containerIndex] };
				updatedContainer.status = newStatus;
				updatedLine.input.containers[containerIndex] = updatedContainer;

				updatedLines[lineIndex] = updatedLine;
			}
			return updatedLines;
		});
	};

	const updateContainerType = (lineIndex: number, containerIndex: number, newType: string) => {
		setLines((prevLines) => {
			const updatedLines = [...prevLines];
			if (updatedLines[lineIndex] && updatedLines[lineIndex].input.containers[containerIndex]) {
				const updatedLine = { ...updatedLines[lineIndex] };
				const updatedContainer = { ...updatedLine.input.containers[containerIndex] };
				updatedContainer.type = newType;
				updatedLine.input.containers[containerIndex] = updatedContainer;

				updatedLines[lineIndex] = updatedLine;
			}
			return updatedLines;
		});
	};

	const updateContainerCommand = (lineIndex: number, containerIndex: number, command: Record<string,any>) => {
		setLines((prevLines) => {
			const updatedLines = [...prevLines];
			if (updatedLines[lineIndex] && updatedLines[lineIndex].input.containers[containerIndex]) {
				const updatedLine = { ...updatedLines[lineIndex] };
				const updatedContainer = { ...updatedLine.input.containers[containerIndex] };
				updatedContainer.command = command;
				updatedLine.input.containers[containerIndex] = updatedContainer;
				updatedLines[lineIndex] = updatedLine;
			}
			return updatedLines;
		});
	};

	const updateInputContainer = (
		lineIndex: number,
		containerIndex: number,
		newChild: React.ReactNode
	) => {
		setLines((prevLines) => {
			const updatedContainers = [...prevLines[lineIndex].input.containers];
			const containerToUpdate = updatedContainers[containerIndex];
			const updatedContainer = {
				...containerToUpdate,
				child: newChild,
			};
			updatedContainers[containerIndex] = updatedContainer;
			const updatedLine = {
				...prevLines[lineIndex],
				input: {
					...prevLines[lineIndex].input,
					containers: updatedContainers,
				},
			};
	
			return [
				...prevLines.slice(0, lineIndex),
				updatedLine,
				...prevLines.slice(lineIndex + 1),
			];
		});
	};

	const updateOutputContainer = (
		lineIndex: number,
		containerIndex: number,
		newChild: React.ReactNode
	) => {
		setLines((prevLines) => {
			const updatedContainers = [...prevLines[lineIndex].output.containers];
			const containerToUpdate = updatedContainers[containerIndex];
			const updatedContainer = {
				...containerToUpdate,
				child: newChild,
			};
			updatedContainers[containerIndex] = updatedContainer;
			const updatedLine = {
				...prevLines[lineIndex],
				output: {
					...prevLines[lineIndex].output,
					containers: updatedContainers,
				},
			};
	
			return [
				...prevLines.slice(0, lineIndex),
				updatedLine,
				...prevLines.slice(lineIndex + 1),
			];
		});
	};

	const updateQuery = (lineIndex: number, containerIndex: number, value: string) => {
        setLines(prevLines => {
            const updatedLines = [...prevLines];
            updatedLines[lineIndex].input.containers[containerIndex].query = value;
            return updatedLines;
        });
    };

    const handleInput = (lineIndex: number, containerIndex: number, value: string) => {
        updateQuery(lineIndex, containerIndex, value);
    };


	const prepareLinesForJSON = (lines: TerminalLineI[]) => {
		const newlines =  lines.map(line => ({
			input: {
				containers: line.input.containers.map(container => {
					if (container) {
						return {
							type: container.query?.endsWith("||") ? "pipe" : container.query?.endsWith("\\\\") ? "scape" : "text",
							query: (container.query?.endsWith("||") || container.query?.endsWith("\\\\")) ? container.query?.slice(0, -2) : container.query?.endsWith("\\\\") ? container.query?.slice(0, -2) : container.query,
							command: container.command,
							status: container.status,
							files: Array.from(container.files).map(file => file.trim()),
						};
					}
					return {
						type: "text",
						query: "",
						command: "",
						status: "",
						files: [],
					};
				}),
			},
			output: {
				containers: line.output.containers.map(container => {
					return {
						type: "text",
						rawText: container.rawText,
					};
				}),
			}
		}));
		
		return newlines;
	};

	const handleSendQuery = async (lineIndex: number, containerIndex: number) => {		
		const input = lines[lineIndex].input.containers[containerIndex].query;
		const token = appcontext?.accessToken;
		if (input?.trim() !== "") {
			try {
				setSendingQuery(true);
				updateOutputContainer(lineIndex, containerIndex,
					<BarLoader/>
				);
				
				const sanitizedLines = prepareLinesForJSON(lines);
				const queryData = JSON.stringify({
					id : "default",
					conversation: sanitizedLines
				});
	
				const response = await fetch("/api/v1/chat/sendQuery", {
					method: "POST",
					headers: {
						'Authorization': `Bearer ${token}`,
						'Content-Type': 'application/json'
					},
					body: queryData,
				});
	
				if (!response.ok || !response.body) {
					throw new Error("Error sending query or empty response body");
				}
	
				const reader = response.body.getReader();
				const decoder = new TextDecoder("utf-8");
	
				let currentText = '';
				const updateContainerText = (newText: string) => {
					setLines((prevLines) => {
						const updatedLines = [...prevLines];
						const updatedLine = { ...updatedLines[lineIndex] };
						const updatedOutputContainers = [...updatedLine.output.containers];
	
						updatedOutputContainers[containerIndex] = {
							...updatedOutputContainers[containerIndex],
							child: (
								<MarkdownRenderer markdown={newText ? newText : ""}></MarkdownRenderer>

							),
							rawText: newText,
						};
						updatedLine.output.containers = updatedOutputContainers;
						updatedLines[lineIndex] = updatedLine;
						return updatedLines;
					});
				};

				while (true) {
					const { done, value } = await reader.read();
					if (done) break;
					const chunk = decoder.decode(value, { stream: true });
					currentText += chunk.toString();
					updateContainerText(currentText);
				}
	
				setSendingQuery(false);
				addNewLine();
				appcontext?.setUpdate({ prompts: true });
			} catch (error) {
				console.error("Error sending query:", error);
				setSendingQuery(false);
			}
		}
	};

	const handleTerminalChange = () => {
        if (terminalRef.current) {
            terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
        }
    };

	useEffect(() => {
        if (terminalRef.current) {
            terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
        }
    }, [lines]);

	return (
		<TerminalContext.Provider value={{
			appendInput,
			createDefaultInput,
			appendContainer,
			resetCommand,
			setResetCommand,
			addNewLine,
			sendingQuery,
			setSendingQuery,
			clearInputContainers,
			addNewContainer,
			resetContainer,
			updateOutputContainer,
			updateInputContainer,
			updateContainerStatus,
			updateContainerType,
			updateContainerCommand,
			updateQuery,
			lines,
			setLines,
			defaultLine,
			defaultInputContainer,
			showCommandOptions,
			setShowCommandOptions,
			uploadFiles,
			uploadComplete,
			setUploadComplete,
			fileInputRefs,
			setUploadFiles,
			isDone,
			setIsDone,
			cancelToken,
			setCancelToken
		}}>
			<div onChange={()=>{}}ref={terminalRef} id="terminal" className="items-center w-[30%] overflow-y-auto bg-neutral-900 panel border-l border-l-neutral-600">
				{lines.map((line, lineIndex) => (
					<React.Fragment key={`terminal-line-${lineIndex}`}>
						{/* Input Section */}
						<TerminalLine length={line.input.containers.length}>
							<div
								className="flex flex-col bg-neutral-700 w-[95%] rounded border border-neutral-500"
							>
								{
									line.input.containers.map((container, containerIndex) => {
										const isLastLine = lineIndex === lines.length - 1;
										const isLastContainer = containerIndex === line.input.containers.length - 1;
										const isEditable = isLastLine && isLastContainer;
										
										return (
											<TerminalInputContainer
												lineIndex={lineIndex}
												containerIndex={containerIndex}
												ref={container.ref}
												key={`input-container-${lineIndex}-${containerIndex}`}
											>
												{
													(isLastLine && isLastContainer) ?
													<textarea
														className="text-sm text-contrast-50 bg-neutral-700 w-full resize-none p-2 outline-none max-h-[25lh] overflow-y-auto"
														ref={(el) => {
															if (el) {
																el.style.height = "auto";
																el.style.height = `${el.scrollHeight}px`;
															}
														}}
														onKeyDown={
															isLastLine && isLastContainer
															? (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
																const currentQuery = lines[lineIndex].input.containers[containerIndex].query;
																if (e.key === 'Enter') {
																	if (e.shiftKey) {
																	const newQuery = currentQuery + "\n";
																	updateQuery(lineIndex, containerIndex, newQuery);
																	} else {
																		e.preventDefault();
																		handleSendQuery(lineIndex, containerIndex);
																	}
																}
																}
															: undefined
														}
														onChange={(e) => {
															const textarea = e.target;
															handleInput(lineIndex, containerIndex, textarea.value);
															textarea.style.height = "auto";
															textarea.style.height = `${textarea.scrollHeight}px`;
														}}
														value={lines[lineIndex].input.containers[containerIndex].query}
														readOnly={!(isLastLine && isLastContainer) || sendingQuery}
													/>
													:
													<div className="text-sm text-contrast-50 bg-neutral-700 w-full resize-none p-2 outline-none max-h-[25lh] overflow-y-auto">
														{container.query}
													</div>
												}
												<CommandOptions lineIndex={lineIndex} containerIndex={containerIndex} key={`commandOptions-${lineIndex}-${containerIndex}`}/>
												<>
													{
														lines[lineIndex].input.containers[containerIndex].files.length > 0 ? <PromptFileUploadList lineIndex={lineIndex} containerIndex={containerIndex} />
														: undefined
													}
												</>
												<>
													{
														<div className="flex flex-row justify-between group">
															<FileInput
																onChange={(event) => {
																	const files = event.target.files;
																	if (files && files.length > 0) {
																		setUploadFiles(files);
																	}
																}}
																ref={(el) => {
																	if (el) {
																	fileInputRefs.current[lineIndex] = fileInputRefs.current[lineIndex] || [];
																	fileInputRefs.current[lineIndex][containerIndex] = el;
																	}
																}}
																lineIndex={lineIndex}
																containerIndex={containerIndex}
															/>
															{
																(
																	lineIndex === lines.length - 1 &&
																	containerIndex === lines[lineIndex].input.containers.length - 1
																) ? 
																(
																	<button
																		disabled={sendingQuery}
																		className="items-center p-2"
																		onClick={() => {
																			const fileInput = fileInputRefs.current[lineIndex][containerIndex];
																			if (fileInput) {
																				fileInput.click();
																			}
																		}}
																	>
																		{lines[lineIndex].input.containers[containerIndex].status !== "uploading" ?
																			<UploadIcon className="text-contrast-50"/> : <CircleSpinner size={15}/>
																		}
																	</button>
																) : undefined
															}
															<button className="items-center self-center p-2 group-hover:visible invisible" onClick={(event) => {
																event.stopPropagation();
																const query = lines[lineIndex].input.containers[containerIndex].query;
																if (query)
																	navigator.clipboard.writeText(query);
																showToast(`Copied text to clipboard`, "success");
															}}><CopyIcon className="text-[var(--text-color)]"/></button>
														</div>
													}
												</>
											</TerminalInputContainer>
									)})
								}
							</div>
						</TerminalLine>
						{line.output.containers.length > 0 && (
							line.output.containers[0].child ?
							<TerminalLine length={line.output.containers.length}>
								<div className="flex flex-col rounded gap-[10px] p-2 bg-neutral-800 w-[95%]">
									{line.output.containers.map((container, i) => (
										container && container.child ?
										<div className="group">
											<TerminalOutputContainer
												lineIndex={lineIndex}
												containerIndex={i}
												ref={container.ref}
												key={`output-container-${lineIndex}-${i}`}
											>
												<div className="prose w-full">
													{container.child}
												</div>
											</TerminalOutputContainer>
											{
												<button className="items-center self-center p-2 group-hover:visible invisible" onClick={(event) => {
													event.stopPropagation();
													const output = lines[lineIndex].output.containers[i].rawText || "";
													navigator.clipboard.writeText(output);
													showToast(`Copied text to clipboard`, "success");
												}}><CopyIcon className="text-[var(--text-color)]"/></button>
											}
										</div>
										: null
									))}
								</div>
							</TerminalLine> : undefined
						)}
					</React.Fragment>
				))}
			</div>
		</TerminalContext.Provider>
	);
}
