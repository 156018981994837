import React, { useEffect, useState } from "react";

export const useIndexedDB = (dbName: string, storeName: string) => {
  const [db, setDb] = useState<IDBDatabase | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const request = indexedDB.open(dbName);

    request.onerror = () => {
      setError("Database failed to open");
    };

    request.onsuccess = () => {
      setDb(request.result);
      setLoading(false);
    };

    request.onupgradeneeded = () => {
      const db = request.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: "id" });
      }
    };
  }, [dbName, storeName]);

  const add = (item: any) => {
    if (!db) {
      setError("Database is not open");
      return;
    }

    const transaction = db.transaction([storeName], "readwrite");
    const store = transaction.objectStore(storeName);
    const request = store.add(item);

    request.onsuccess = () => {
      
    };

    request.onerror = () => {
      setError("Failed to add item to the database");
    };
  };

  const remove = (id: string | number) => {
    if (!db) {
      setError("Database is not open");
      return;
    }

    const transaction = db.transaction([storeName], "readwrite");
    const store = transaction.objectStore(storeName);
    const request = store.delete(id);

    request.onsuccess = () => {
      
    };

    request.onerror = () => {
      setError("Failed to remove item from the database");
    };
  };

  const clear = () => {
    if (!db) {
      setError("Database is not open");
      return;
    }

    const transaction = db.transaction([storeName], "readwrite");
    const store = transaction.objectStore(storeName);
    const request = store.clear();

    request.onsuccess = () => {
      
    };

    request.onerror = () => {
      setError("Failed to clear store");
    };
  };

  const get = (id: string | number) => {
    return new Promise((resolve, reject) => {
      if (!db) {
        setError("Database is not open");
        reject("Database is not open");
        return;
      }

      const transaction = db.transaction([storeName], "readonly");
      const store = transaction.objectStore(storeName);
      const request = store.get(id);

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = () => {
        setError("Failed to retrieve item from the database");
        reject("Failed to retrieve item from the database");
      };
    });
  };

  return { add, remove, clear, get, error, loading };
};
