import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { xonokai } from 'react-syntax-highlighter/dist/cjs/styles/prism';


export default function MarkdownRenderer({ markdown }: { markdown: string }) {
  return (
    <Markdown
      className="flex flex-col w-full p-2 text-sm break-words"
      remarkPlugins={[remarkGfm]}
      components={{
        code({ node, inline, className, children, ...props }: any) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter
              style={xonokai}
              PreTag="div"
              language={match[1]}
              {...props}
              className="w-full"
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code className={`${className} w-full`} {...props}>
              {children}
            </code>
          );
        },
        h1: ({ children }) => <h1 className="w-full text-neutral-100 text-2xl font-bold">{children}</h1>,
        h2: ({ children }) => <h2 className="w-full text-neutral-100 text-xl font-semibold">{children}</h2>,
        h3: ({ children }) => <h3 className="w-full text-neutral-100 text-lg font-medium">{children}</h3>,
        h4: ({ children }) => <h4 className="w-full text-neutral-100 text-lg font-semibold">{children}</h4>,
        p: ({ children }) => <p className="w-full text-sm text-neutral-100">{children}</p>,
        strong: ({ children }) => <strong className="font-semibold text-sm text-gray-100 w-full">{children}</strong>,
        a: ({ children, href }) => <a className="text-blue-200" href={href}>{children}</a>,
        ul: ({ children }) => <ul className="w-full text-neutral-100 list-disc pl-5">{children}</ul>,
        ol: ({ children }) => <ol className="w-full text-neutral-100 list-decimal pl-5">{children}</ol>,
        blockquote: ({ children }) => <blockquote className="w-full text-neutral-100 border-l-4 pl-4 italic">{children}</blockquote>,
        pre: ({ children }) => <pre className="w-full text-neutral-100 overflow-auto">{children}</pre>,
        table: ({ children }) => <table className="w-full text-neutral-100 border-collapse">{children}</table>,
        thead: ({ children }) => <thead className="w-full text-neutral-100 bg-neutral-400">{children}</thead>,
        tbody: ({ children }) => <tbody className="w-full text-neutral-100">{children}</tbody>,
        tr: ({ children }) => <tr className="w-full border-b text-neutral-100">{children}</tr>,
        th: ({ children }) => <th className="w-full font-semibold text-neutral-100">{children}</th>,
        td: ({ children }) => <td className="w-full text-neutral-100">{children}</td>,
      }}
    >
      {markdown}
    </Markdown>
  );
}