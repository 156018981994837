import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import envVariables from './env';
import { access } from 'fs';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const onRedirectCallback = (appState) => {
//   history.pushState(
//     appState && appState.returnTo ? appState.returnTo : window.location.pathname
//   );
// };

root.render(
  <Auth0Provider
    domain={envVariables.auth0Domain}
    clientId={envVariables.auth0ClientId}
    authorizationParams={
    {
      redirect_uri: envVariables.hostname + '/app',
      audience: "https://metalossmvp.api.com",
      scope: "openid profile email read:current_user update:current_user_metadata",
    }}
    useRefreshTokens={true}
  >
    <App/>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
